/** @jsx jsx */
import React from 'react'
import { Box } from 'theme-ui'
import { jsx } from 'theme-ui'

export const Callout = React.memo(() => (
  <Box
    sx={{
      p: 2,
      borderRadius: '8px',
      textAlign: 'center',
      backgroundColor: 'detail',
    }}
  >
    <h2
      sx={{
        fontFamily: 'heading',
        fontWeight: 'heading',
        letterSpacing: 1,
        lineHeight: 2,
        fontSize: 1,
        m: 0,
        color: 'text',
      }}
    >
      Envie de discuter ?
    </h2>

    <p
      sx={{
        fontFamily: 'body',
        fontWeight: 'body',
        letterSpacing: 1,
        lineHeight: 2,
        fontSize: 0,
        m: 0,
        color: 'text',
      }}
    >
      Vous pouvez me contacter par e-mail à{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:bonjour+web@marvinroger.fr"
        sx={{ color: 'text' }}
      >
        bonjour+web@marvinroger.fr
      </a>
      .
      <br />
      Vous trouverez également mon numéro de téléphone sur mon{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="/cv.pdf"
        sx={{ color: 'text' }}
      >
        C.V.
      </a>
    </p>
  </Box>
))
Callout.displayName = 'Callout'
