/** @jsx jsx */
import { MDXProvider } from '@mdx-js/react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useMemo } from 'react'
import { Box, Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { Blockquote } from '../components/mdx/Blockquote'
import { Callout } from '../components/mdx/Callout'
import { Code } from '../components/mdx/Code'
import { Link } from '../components/mdx/Link'
import { ListLi, UlList } from '../components/mdx/List'
import { Paragraph } from '../components/mdx/Paragraph'
import { Title2 } from '../components/mdx/Title2'
import { MyFace } from '../components/MyFace/MyFace'
import { SEO } from '../components/SEO'
import { StylizedTitle } from '../components/StylizedTitle'
import { gradientMixin } from '../components/TextGradient'
import { PostQuery } from '../types/gatsby-queries'
import { useReadingProgress } from '../hooks/use-reading-progress'
import { MainLayout } from '../layouts/main'
import { PageProps } from '../utils/types'

const MDX_COMPONENTS = {
  p: Paragraph,
  blockquote: Blockquote,
  a: Link,
  code: Code,
  h2: Title2,
  ul: UlList,
  li: ListLi,
}

const PostTemplate = React.memo<PageProps<PostQuery>>(({ data, location }) => {
  const { mdx } = data
  const { body, excerpt } = mdx
  const { date, title, type, subtitle, featuredImage } = mdx.frontmatter
  const featuredImgFluid = featuredImage.childImageSharp.post

  const titleSuffix = useMemo(() => {
    if (type === 'project') {
      return ' (projet)'
    }

    if (type === 'skill') {
      return ' (compétence)'
    }

    return ''
  }, [type])

  const readingTarget = React.createRef<HTMLElement>()
  const readingProgress = useReadingProgress(readingTarget)

  return (
    <>
      <SEO
        title={`${title}${titleSuffix}`}
        location={location}
        image={featuredImage}
        description={excerpt}
        article={true}
      />
      <MainLayout>
        <Box
          sx={{
            width: `100vw`,
            height: '3px',
            transform: `translateX(${readingProgress}vw)`,
            position: 'fixed',
            top: 0,
            left: '-100vw',
            zIndex: 2,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(gradientMixin as any),
          }}
        />
        <Box ref={readingTarget}>
          {['skill', 'project'].includes(type) && (
            <GatsbyLink
              to={type === 'skill' ? '/skills' : '/projects'}
              sx={{
                display: 'flex',
                fontFamily: 'heading',
                fontWeight: 'heading',
                fontSize: [1, 2],
                letterSpacing: [1, 2],
                color: 'primary1',
                textDecoration: 'underline',
                mb: [3, 4],
              }}
            >
              // {type === 'skill' ? 'Compétences' : 'Projets'}
            </GatsbyLink>
          )}

          <StylizedTitle as="h1" sx={{ fontSize: [3, 4] }}>
            {title}
          </StylizedTitle>

          <Box
            as="h2"
            sx={{
              fontFamily: 'heading',
              fontWeight: 'heading',
              fontSize: [2, 3],
              letterSpacing: [1, 2],
              color: 'lightText',
            }}
          >
            {subtitle}
          </Box>

          <Flex
            sx={{
              mt: [3, 4],
              alignItems: 'center',
              fontFamily: 'body',
              fontWeight: 'body',
              fontSize: 0,
              letterSpacing: [1, 2],
            }}
          >
            <MyFace sx={{ width: ['48px', '84px'] }} />

            <Flex
              sx={{
                flexDirection: 'column',
                ml: [3, 4],
              }}
            >
              <Box>Marvin ROGER</Box>
              {date && <Box sx={{ color: 'lightText', mt: 2 }}>Le {date}</Box>}
            </Flex>
          </Flex>

          <Box
            sx={{
              mt: 4,
            }}
          >
            <Box
              sx={{
                width: '100vw',
                position: 'relative',
                left: '50%',
                right: '50%',
                ml: '-50vw',
                mr: '-50vw',
              }}
            >
              <GatsbyImage
                alt="Image d'illustration"
                image={featuredImgFluid}
              />
            </Box>
            <MDXProvider components={MDX_COMPONENTS}>
              <MDXRenderer>{body}</MDXRenderer>
            </MDXProvider>
            <Callout />
          </Box>
        </Box>
      </MainLayout>
    </>
  )
})
PostTemplate.displayName = 'PostTemplate'

export default PostTemplate

export const query = graphql`
  query PostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 160)
      body
      frontmatter {
        type
        title
        subtitle
        date(formatString: "D MMMM YYYY", locale: "fr-FR")
        featuredImage {
          childImageSharp {
            post: gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            ...SEOImage
          }
        }
      }
    }
  }
`
