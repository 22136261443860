import { useEffect, useState } from 'react'

export const useReadingProgress = (target: React.RefObject<HTMLElement>) => {
  const [readingProgress, setReadingProgress] = useState(0)
  const scrollListener = () => {
    if (!target.current) {
      return
    }

    const element = target.current
    const elementHeight = element.clientHeight
    const minScroll = element.offsetTop
    const maxScroll = element.offsetTop + elementHeight - window.innerHeight
    const delta = maxScroll - minScroll

    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0

    if (windowScrollTop <= minScroll) {
      return setReadingProgress(0)
    }

    // min 0
    // max 100
    // scroll ?

    if (windowScrollTop > maxScroll) {
      return setReadingProgress(100)
    }

    setReadingProgress(((windowScrollTop - minScroll) / delta) * 100)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  })

  return readingProgress
}
