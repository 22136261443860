/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { StylizedTitle } from '../StylizedTitle'
import { mdxMargin } from './common'

export const Title2 = React.memo(({ children }) => (
  <StylizedTitle
    as="h2"
    sx={{
      ...mdxMargin,
      fontSize: 1,
    }}
  >
    {children}
  </StylizedTitle>
))
Title2.displayName = 'Title2'
