/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { mdxMargin, mdxText } from './common'

interface UlListProps {
  children: React.ReactNode
}

export const UlList = React.memo<UlListProps>(({ children }) => (
  <ul
    sx={{
      ...mdxText,
      ...mdxMargin,
      fontSize: 0,
    }}
  >
    {children}
  </ul>
))
UlList.displayName = 'UlList'

interface ListLiProps {
  children: React.ReactNode
}

export const ListLi = React.memo<ListLiProps>(({ children }) => (
  <li>{children}</li>
))
UlList.displayName = 'UlList'
