/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { mdxMargin, mdxText } from './common'

interface Props {
  children: React.ReactNode
}

export const Paragraph = React.memo<Props>(({ children }) => (
  <p
    sx={{
      ...mdxText,
      ...mdxMargin,
      fontSize: 0,
    }}
  >
    {children}
  </p>
))
Paragraph.displayName = 'Paragraph'
