/** @jsx jsx */
import React from 'react'
import { Image } from 'theme-ui'
import { jsx } from 'theme-ui'
import marvinPng from './marvin.png'

const MyFace = React.memo<{ className?: string }>(props => (
  <Image
    className={props.className}
    sx={{
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, .14)',
      borderRadius: '100%',
    }}
    src={marvinPng}
    alt="Marvin ROGER"
  />
))

export { MyFace }
