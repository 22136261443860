/** @jsx jsx */
import { Link as GatsbyLink } from 'gatsby'
import React, { useMemo } from 'react'
import { jsx } from 'theme-ui'

interface Props {
  children: string
  href: string
}

const LINK_STYLE = { color: 'primary1', textDecoration: 'underline' }

export const Link = React.memo<Props>(props => {
  const isInternal = useMemo(() => {
    return props.href.startsWith('/')
  }, [props])

  return isInternal ? (
    <GatsbyLink to={props.href} sx={LINK_STYLE}>
      {props.children}
    </GatsbyLink>
  ) : (
    <a
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      sx={LINK_STYLE}
    >
      {props.children}
    </a>
  )
})
Link.displayName = 'Link'
