/** @jsx jsx */
import React from 'react'
import { Box, Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { gradientMixin } from '../TextGradient'
import { mdxMargin } from './common'

export const Blockquote = React.memo(({ children }) => (
  <Flex
    sx={{
      ...mdxMargin,
      position: 'relative',
      backgroundColor: 'detail',
      borderRadius: '8px',
      px: 3,
      fontStyle: 'italic',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '4px',
        borderRadius: '8px 0 0 8px',
        ...(gradientMixin as any),
      }}
    />
    {children}
  </Flex>
))
Blockquote.displayName = 'Blockquote'
