/** @jsx jsx */
import Highlight, { defaultProps, Language } from 'prism-react-renderer'
import React from 'react'
import { jsx } from 'theme-ui'
import oneDarkProPrismTheme from './prism-theme'

interface Props {
  children: string
  className: string
}

export const Code = React.memo<Props>(({ children, className }) => {
  const language = className?.replace(/language-/, '')
  return (
    <Highlight
      {...defaultProps}
      theme={oneDarkProPrismTheme}
      code={children.trim()}
      language={language as Language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{
            ...style,
            padding: '20px',
            borderRadius: '8px',
            overflowY: 'auto',
          }}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
})
Code.displayName = 'Code'
